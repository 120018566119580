
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzModal from "@/components/Modal.vue";
  import { namespace } from "vuex-class";
  import VCalendar from "v-calendar/lib/components/calendar.umd";
  import { apiServices } from "../api";
  import SearchEvent from "@/components/SearchEvent.vue";
  import DesignModal from "../components/DesignModal.vue";
  import SectionToggle from "@/components/SectionToggle.vue";
  const auth = namespace("auth");

  @Component({
    components: {
      LzButton,
      VCalendar,
      LzModal,
      SearchEvent,
      DesignModal,
      SectionToggle
    }
  })
  export default class Read extends Vue {
    @auth.State("id") private memberId!: string;
    @auth.State("url") websiteURL!: string;

    public services = [] as Service[];

    async mounted() {
      await this.loadServices();
    }

    async loadServices() {
      if (!this.memberId) return;

      const services = await apiServices
        .getAllByMemberId(this.memberId)
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic")
          });
        });

      if (services) {
        this.services = services;
      }
    }

    create() {
      this.$router.push({ name: "servicesCreate" });
    }
    edit(serviceId: string) {
      this.$router.push({
        name: "servicesCreate",
        params: { serviceId }
      });
    }
    async deleteService(serviceId: string) {
      await apiServices
        .delete(serviceId)
        .then(() => {
          this.loadServices();
          this.$notify({
            type: "success",
            text: this.$tc("services.create.notifications.removed")
          });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic")
          });
        });
    }

    query = "";
    get searchResults() {
      return this.services.filter(service =>
        service.service_name.toLowerCase().includes(this.query.toLowerCase())
      );
    }
    handleSearch(text: string) {
      this.query = text;
    }
  }
